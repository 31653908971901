import {
  PButtonPure,
  PIcon,
  PText,
} from '@porsche-design-system/components-react';
import { useDropzone } from 'react-dropzone';
import { styled } from '../stitches.config';

const byteSized = (bytes: number) => {
  return ['Bytes', 'KB', 'MB', 'GB', 'TB'].reduce(
    (acc: number | string, abbr: string) => {
      if (typeof acc === 'number') {
        return acc > 1000 ? acc / 1000 : `${Math.round(acc * 10) / 10} ${abbr}`;
      }

      return acc;
    },
    bytes,
  );
};

export const Dropzone = styled('div', {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  borderRadius: '$small',
  alignItems: 'center',
  padding: '$large',
  borderWidth: '2px',
  borderColor: '$contrastMedium',
  borderStyle: 'dashed',
  color: '$contrastHigh',
  outline: 'none',
  cursor: 'pointer',
});

const Files = styled('ul', {
  padding: '0',
  margin: '0',
  display: 'block',
});

const File = styled('li', {
  listStyleType: 'none',
  display: 'flex',
  position: 'relative',
  paddingTop: '$small',
  paddingBottom: '$small',
  borderBottom: '1px solid $contrastLow',
  gap: '$small',
});

const FileName = styled('span', {
  display: 'inline-block',
  paddingLeft: '$xSmall',
  paddingRight: '$xSmall',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
});

const FileSize = styled('span', {
  marginLeft: 'auto',
  whiteSpace: 'nowrap',
});

const ProgressBar = styled('div', {
  height: '6px',
  bottom: 0,
  left: 0,
  backgroundColor: '$success',
  borderRadius: '$medium',
  position: 'absolute',
  transition: 'width 0.5s 0s ease',
});

export const DropzoneInner = ({
  isDragActive,
  description,
  dragDescription,
}: {
  isDragActive: boolean;
  description?: string;
  dragDescription?: string;
}) => (
  <>
    {isDragActive && dragDescription ? (
      <PText>{dragDescription}</PText>
    ) : (
      description && <PText>{description}</PText>
    )}
    <PIcon name="upload" />
  </>
);

export const DropZoneFiles = ({
  files,
  existingFiles,
  progress,
  onDelete,
  onDeleteExisting,
}: {
  files: {
    id: string;
    file: File;
  }[];
  existingFiles?: {
    id: string;
    name: string;
    size: number;
    type: string;
  }[];
  progress: number[];
  onDelete?: (index: number) => void;
  onDeleteExisting?: (id: string) => void;
}) => (
  <Files>
    {files.map((file, index) => (
      <File
        key={`${file.file.name}_${index}`}
        aria-label={file.file.name}
        data-cy="upload"
      >
        {progress[index] > 0 && (
          <ProgressBar style={{ width: `${progress[index]}%` }} />
        )}
        {onDelete && (
          <PButtonPure
            icon="delete"
            size="small"
            onClick={() => onDelete(index)}
            role="button"
            type="button"
            aria-label="delete attachment"
            hideLabel
          />
        )}
        <FileName>{file.file.name}</FileName>
        <FileSize>{byteSized(file.file.size)}</FileSize>
      </File>
    ))}
    {existingFiles?.map((file) => (
      <File key={file.id} aria-label={file.name}>
        {onDeleteExisting && (
          <PButtonPure
            icon="delete"
            size="small"
            onClick={() => onDeleteExisting(file.id)}
            role="button"
            type="button"
            aria-label="delete attachment"
            hideLabel
          />
        )}
        <FileName>{file.name}</FileName>
        <FileSize>{byteSized(file.size)}</FileSize>
      </File>
    ))}
  </Files>
);

export { useDropzone };
