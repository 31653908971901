import {
  borderRadius,
  breakpoint,
  spacingStatic,
  themeDark,
  themeLight,
} from '@porsche-design-system/components-react/styles';
import type { CSS } from '@stitches/react';
import { createStitches } from '@stitches/react';

const mediaQueries = Object.entries(breakpoint)
  .map(([key, value]) => ({ [key]: `(min-width: ${value}px)` }))
  .reduce(
    (obj, item) => ({
      ...obj,
      ...item,
    }),
    {},
  );

export type { CSS };

export const {
  styled,
  css,
  globalCss,
  keyframes,
  getCssText,
  theme,
  createTheme,
  config,
} = createStitches({
  theme: {
    colors: {
      active: themeLight.state.active,

      backgroundBase: themeLight.background.base,
      backgroundSurface: themeLight.background.surface,
      backgroundShading: themeLight.background.shading,
      darkBackgroundBase: themeDark.background.base,

      highlight: '#fc4040',
      highlightShading: '#f00',
      highlightContrast: '#fff',

      default: themeLight.primary,
      text: themeLight.primary,
      brand: themeLight.primary,

      contrastLow: themeLight.contrast.low,
      contrastMedium: themeLight.contrast.medium,
      contrastHigh: themeLight.contrast.high,

      disabled: themeLight.state.disabled,
      hover: themeLight.state.hover,

      error: themeLight.notification.error,
      success: themeLight.notification.success,

      white: '#fff',
      black: '#000',
    },
    radii: borderRadius,
    space: spacingStatic,
    sizes: { ...spacingStatic, content: '1000px' },
    zIndices: {
      header: 0,
      default: 1,
      sidebarOverlay: 8,
      sidebar: 9, // PDS select dropdown is hard-coded at 10
      overlay: 10,
      devTools: 99999,
      tooltip: 110000, // PDS modal is hard-coded at 99999
      popover: 100000, // PDS modal is hard-coded at 99999
    },
  },
  media: mediaQueries,
});

const globalStyles = globalCss({
  '*': { margin: 0, padding: 0, colorScheme: 'light' },

  body: {
    fontFamily: 'Porsche Next',
    color: '$text',
  },
});

globalStyles();
