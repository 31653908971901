import { createFileRoute } from '@tanstack/react-router';
import { RouteGuard } from '../../components';
import { PersonInfo } from '../../pages';

export const Route = createFileRoute('/admin/person-info')({
  component: () => (
    <RouteGuard can="read" a="PersonInfo">
      <PersonInfo.Overview />
    </RouteGuard>
  ),
});
