import {
  PButtonPure,
  PLinkPure,
  PText,
} from '@porsche-design-system/components-react';
import {
  InlineNavigation,
  NavigationItem,
  NavigationLink,
  Popover,
  Spacer,
} from '@porsche-kado/ui';
import { Link } from '@tanstack/react-router';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAbilityContext } from '../../context';

type Item = {
  to: string;
  label: string;
};

export const AdminNavigation = () => {
  const { t } = useTranslation();
  const ability = useAbilityContext();
  const [isAdminMenuOpen, setIsAdminMenuOpen] = useState(false);

  const items = [
    ability.can('manage', 'Analytics') && {
      to: '/admin/analytics',
      label: t('admin.analytics.headline'),
    },
    ability.can('manage', 'PpnMasterdataImportStatus') && {
      to: '/admin/ppn-masterdata-import',
      label: t('admin.ppnMasterdataImport.headline'),
    },
    ability.can('manage', 'FeatureArticle') && {
      to: '/admin/news',
      label: t('featureArticles'),
    },
    ability.can('read', 'PersonInfo') && {
      to: '/admin/person-info',
      label: t('admin.personInfo.headline'),
    },
  ].filter((item): item is Item => !!item);

  if (items.length < 1) {
    return null;
  }

  return (
    <NavigationItem data-testid="admin">
      <Popover
        open={isAdminMenuOpen}
        onChange={setIsAdminMenuOpen}
        spacing="large"
        offset={[-22, 0]}
        placement="bottom-end"
        target={
          <NavigationLink
            as={PButtonPure}
            icon="configurate"
            hideLabel
            onClick={() => setIsAdminMenuOpen((isOpen) => !isOpen)}
          />
        }
      >
        <PText weight="bold">{t('admin.headline')}</PText>

        <Spacer pb="$small" />

        <InlineNavigation>
          {items.map((item) => (
            <li key={item.to}>
              <PLinkPure icon="none">
                <Link onClick={() => setIsAdminMenuOpen(false)} to={item.to}>
                  {item.label}
                </Link>
              </PLinkPure>
            </li>
          ))}
        </InlineNavigation>
      </Popover>
    </NavigationItem>
  );
};
