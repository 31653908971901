
      import { BrowserAgent } from '@newrelic/browser-agent/loaders/browser-agent';

      // Populate using values in copy-paste JavaScript snippet.
      const options = {
        init: {
          distributed_tracing: { enabled: true },
          privacy: { cookies_enabled: true },
          ajax: { deny_list: ['bam.eu01.nr-data.net', 'ipv4.icanhazip.com'] },
        },
        loader_config: {
          accountID: '3162147',
          trustKey: '2619278',
          agentID: '325935080',
          licenseKey: 'NRJS-df3e3629171e4654d32',
          applicationID: '325935080',
        },
        info: {
          beacon: 'bam.eu01.nr-data.net',
          errorBeacon: 'bam.eu01.nr-data.net',
          licenseKey: 'NRJS-df3e3629171e4654d32',
          applicationID: '325935080',
          sa: 1,
        },
      };

      // The agent loader code executes immediately on instantiation.
      new BrowserAgent(options);
    