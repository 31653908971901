import React from 'react';
import { PersonQuery } from '../graphql';

export enum PersonActionKind {
  Authenticating = 'AUTHENTICATING',
  Authenticate = 'AUTHENTICATE',
  Authorize = 'AUTHORIZE',
  ChangeLocale = 'CHANGE_LOCALE',
  Reset = 'RESET',
}

type Person = NonNullable<PersonQuery['person']>;

type Action =
  | {
      type: PersonActionKind.Authenticating;
    }
  | {
      type: PersonActionKind.Authenticate;
      payload: { ppnuid: string };
    }
  | {
      type: PersonActionKind.Authorize;
      payload: Person;
    }
  | {
      type: PersonActionKind.ChangeLocale;
      payload: string;
    }
  | {
      type: PersonActionKind.Reset;
    };

type Dispatch = (action: Action) => void;
type PersonProviderProps = { children: React.ReactNode };
type State = {
  isAuthenticating: boolean;
  isAuthorized: boolean;
  isAuthenticated: boolean;
} & Partial<Person>;

const PersonContext = React.createContext<
  { state: State; dispatch: Dispatch } | undefined
>(undefined);

const personReducer = (state: State, action: Action) => {
  switch (action.type) {
    case PersonActionKind.Authenticating: {
      return {
        ...state,
        isAuthenticating: true,
      };
    }
    case PersonActionKind.Authenticate: {
      const { ppnuid } = action.payload;
      return {
        ppnuid,
        isAuthenticating: false,
        isAuthenticated: !!ppnuid,
        isAuthorized: false,
      };
    }
    case PersonActionKind.Authorize: {
      return {
        ...action.payload,
        isAuthenticating: false,
        isAuthorized: !!action.payload,
        isAuthenticated: true,
      };
    }
    case PersonActionKind.ChangeLocale: {
      return {
        ...state,
        settings: {
          ...state.settings,
          locale: action.payload,
        },
      };
    }
    case PersonActionKind.Reset: {
      return {
        isAuthenticating: false,
        isAuthorized: false,
        isAuthenticated: false,
      };
    }
    default: {
      throw new Error('Unhandled action type');
    }
  }
};

const PersonProvider = ({ children }: PersonProviderProps) => {
  const [state, dispatch] = React.useReducer(personReducer, {
    isAuthenticating: false,
    isAuthenticated: false,
    isAuthorized: false,
  });
  const value = { state, dispatch };
  return (
    <PersonContext.Provider value={value}>{children}</PersonContext.Provider>
  );
};

const usePersonContext = () => {
  const context = React.useContext(PersonContext);
  if (context === undefined) {
    throw new Error('usePersonContext must be used within a PersonContext');
  }
  return context;
};

export { PersonProvider, usePersonContext };
