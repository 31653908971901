import {
  PSegmentedControl,
  PSegmentedControlItem,
  PSelectWrapper,
} from '@porsche-design-system/components-react';
import { styled } from '@stitches/react';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import { useState } from 'react';
import { Label } from './Label';
dayjs.extend(customParseFormat);

export type Meridiem = 'AM' | 'PM';

export const get24HFormat = (hour: number, meridiem: Meridiem) =>
  parseInt(dayjs(`${hour % 12} ${meridiem}`, 'h A').format('H'));

export const getAmPm = (hour: number) =>
  dayjs().set('hour', hour).format('A') as Meridiem;

const Layout = styled('div', {
  display: 'grid',
  gridTemplateColumns: '160px auto',
  gap: '$medium',
});

const AmPm = styled('div', {
  minWidth: '135px',
});

export const HourSelect = ({
  value,
  isRequired,
  isDisabled,
  is24HourFormat,
  label,
  onChange,
}: {
  value: number;
  isRequired?: boolean;
  isDisabled?: boolean;
  is24HourFormat: boolean;
  label?: string;
  onChange: (hour: number) => void;
}) => {
  const [meridiem, setMeridiem] = useState<Meridiem>(getAmPm(value));

  return (
    <>
      {label && <Label isRequired={isRequired} label={label} />}
      <Layout>
        <PSelectWrapper data-testid="hour-select" hideLabel={true}>
          <select
            disabled={isDisabled}
            required={isRequired}
            value={value ? value % (is24HourFormat ? 24 : 12) : ''}
            onChange={(e) => {
              onChange(
                is24HourFormat
                  ? parseInt(e.target.value)
                  : get24HFormat(parseInt(e.target.value), meridiem),
              );
            }}
          >
            {Array.from({
              length: is24HourFormat ? 24 : 12,
            }).map((_, index) => (
              <option key={index} value={index}>
                {dayjs()
                  .set('hour', index)
                  .format(is24HourFormat ? 'HH:00' : 'hh:00')}
              </option>
            ))}
          </select>
        </PSelectWrapper>

        {!is24HourFormat && (
          <AmPm>
            <PSegmentedControl
              columns="2"
              value={meridiem}
              onUpdate={(event) => {
                const m = event.detail.value as Meridiem;
                setMeridiem(m);
                onChange(get24HFormat(value, m));
              }}
            >
              <PSegmentedControlItem disabled={isDisabled} value="AM">
                AM
              </PSegmentedControlItem>
              <PSegmentedControlItem disabled={isDisabled} value="PM">
                PM
              </PSegmentedControlItem>
            </PSegmentedControl>
          </AmPm>
        )}
      </Layout>
    </>
  );
};
