import {
  PModal,
  type PModalProps,
} from '@porsche-design-system/components-react';
import { useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { styled } from '../stitches.config';

const ContentWrapper = styled('div', {
  maxWidth: '100%',
  width: '660px',

  variants: {
    size: {
      small: {
        '@s': {
          width: '330px',
        },
      },
      large: {
        width: '990px',
      },
      max: {
        width: '1550px',
      },
    },
  },
});

export const Modal = ({
  size,
  children,
  open,
  onTransitionEnd,
  ...props
}: {
  size?: 'small' | 'large' | 'max';
  children: React.ReactNode;
} & Omit<PModalProps, 'fullscreen'>) => {
  const [mounted, setMounted] = useState(false);
  const ref = useRef<HTMLElement>();
  useEffect(() => {
    setMounted(true);
    ref.current = document.createElement('div');
    document.body.appendChild(ref.current);

    return () => {
      ref.current && document.body.removeChild(ref.current);
    };
  }, []);

  const [remainOpen, setRemainOpen] = useState(open);

  useEffect(() => {
    if (open) {
      setRemainOpen(true);
    }
  }, [open]);

  const showContent = open || remainOpen;

  if (!mounted || !ref.current) {
    return null;
  }

  return createPortal(
    <PModal
      data-testid="modal"
      role="dialog"
      fullscreen={{ base: true, s: false }}
      open={open}
      onTransitionEnd={(event) => {
        onTransitionEnd?.(event);

        if (event.propertyName === 'opacity' && !open) {
          setRemainOpen(false);
        }
      }}
      {...props}
    >
      <ContentWrapper size={size}>{showContent && children}</ContentWrapper>
    </PModal>,
    ref.current,
  );
};

export const ModalActions = styled('div', {
  display: 'flex',
  justifyContent: 'space-between',
  marginTop: '$medium',
});
