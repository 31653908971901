import { PButton, PText } from '@porsche-design-system/components-react';
import { Modal, ModalActions } from './Modal';

export const ConfirmationModal = ({
  open,
  onDismiss,
  onConfirm,
  heading,
  description,
  i18n,
  isLoading,
}: {
  open: boolean;
  onDismiss: () => void;
  onConfirm: () => void;
  heading: string;
  description: string;
  i18n: {
    cancel: string;
    confirm: string;
  };
  isLoading?: boolean;
}) => (
  <Modal
    open={open}
    onDismiss={() => {
      if (isLoading) return;
      onDismiss();
    }}
    heading={heading}
  >
    <PText>{description}</PText>

    <ModalActions>
      <PButton
        type="button"
        role="button"
        variant="secondary"
        icon="close"
        disabled={isLoading}
        onClick={onDismiss}
      >
        {i18n.cancel}
      </PButton>
      <PButton
        type="button"
        role="button"
        variant="primary"
        loading={isLoading}
        onClick={onConfirm}
      >
        {i18n.confirm}
      </PButton>
    </ModalActions>
  </Modal>
);
