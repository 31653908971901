import {
  PLinkPure,
  PSelectWrapper,
  PText,
} from '@porsche-design-system/components-react';
import { locales } from '@porsche-kado/i18n';
import {
  ContentWrapper,
  Footer as StyledFooter,
  styled,
} from '@porsche-kado/ui';
import { useQueryClient } from '@tanstack/react-query';
import { Link } from '@tanstack/react-router';
import { useTranslation } from 'react-i18next';
import { version } from '../../package.json';
import { COMMON_NAMESPACE } from '../config/i18n';
import { PersonActionKind, usePersonContext } from '../context';
import {
  PersonQuery,
  usePersonQuery,
  useUpdatePersonSettingsMutation,
} from '../graphql';

const Content = styled('div', {
  display: 'grid',
  gridTemplateColumns: '1fr minmax(7em, auto)',
});

const Navigation = styled('ul', {
  listStyleType: 'none',
  margin: '0 0 $large 0',
  padding: 0,
});

const NavigationItem = styled('li', {
  margin: 0,
  padding: '0 $large 0 0',
  display: 'inline-block',
});

export const Footer = (): JSX.Element => {
  const queryClient = useQueryClient();
  const { dispatch: dispatchPerson, state: personState } = usePersonContext();
  const {
    t,
    i18n: { language, changeLanguage, isInitialized },
  } = useTranslation(COMMON_NAMESPACE);

  const { mutate: updatePersonSettings } = useUpdatePersonSettingsMutation({
    onSuccess: (data) => {
      const cacheKey = usePersonQuery.getKey({
        ppnuid: data.updatePersonSettings.ppnuid,
      });
      const cache = queryClient.getQueryData<PersonQuery>(cacheKey);

      if (cache?.person) {
        queryClient.setQueryData<PersonQuery>(cacheKey, {
          person: {
            ...cache.person,
            settings: {
              ...cache.person?.settings,
              ...data.updatePersonSettings.settings,
            },
          },
        });
      }
    },
  });

  return (
    <StyledFooter>
      <ContentWrapper>
        <Content>
          <nav>
            <Navigation>
              <NavigationItem>
                <PLinkPure theme="dark" icon="none">
                  <Link to="/imprint">{t('footer.imprint')}</Link>
                </PLinkPure>
              </NavigationItem>
              <NavigationItem>
                <PLinkPure theme="dark" icon="none">
                  <Link to="/privacy-policy">{t('footer.privacyPolicy')}</Link>
                </PLinkPure>
              </NavigationItem>
              <NavigationItem>
                <PLinkPure theme="dark" icon="none">
                  <Link to="/cookie-policy">{t('footer.cookiePolicy')}</Link>
                </PLinkPure>
              </NavigationItem>
              {personState.isAuthenticated && (
                <NavigationItem>
                  <PLinkPure theme="dark" icon="none">
                    <Link to="/help">{t('footer.help')}</Link>
                  </PLinkPure>
                </NavigationItem>
              )}
            </Navigation>
          </nav>
          <PSelectWrapper theme="dark" hideLabel dropdownDirection="up">
            <select
              onChange={(event) => {
                changeLanguage(event.target.value);

                if (personState.isAuthenticated && personState.isAuthorized) {
                  updatePersonSettings({
                    input: {
                      locale: event.target.value,
                    },
                  });

                  dispatchPerson({
                    type: PersonActionKind.ChangeLocale,
                    payload: event.target.value,
                  });
                }
              }}
              value={language}
              name="language"
              disabled={!isInitialized}
            >
              {locales.map(({ code }) => (
                <option value={code} key={code}>
                  {t(`language.${code as 'en-GB'}`)}
                </option>
              ))}
            </select>
          </PSelectWrapper>
        </Content>
        <PText theme="dark" color="contrast-medium">
          © {new Date().getFullYear()} Dr. Ing. h.c. F. Porsche AG | Version{' '}
          {version}
        </PText>
      </ContentWrapper>
    </StyledFooter>
  );
};
