import { PIcon } from '@porsche-design-system/components-react';
import { styled } from '../../stitches.config';
import { Tooltip } from '../Tooltip';
import { CharacterCounter } from './CharacterCounter';
import { HistoryToolbarButtons } from './HistoryToolbarButtons';
import {
  BasicMarksToolbarButtons,
  ImageToolbarButtons,
  ListToolbarButtons,
} from './plugins';

export const Container = styled('div', {
  display: 'flex',
  paddingBottom: '$small',
});

const Seperator = styled('div', {
  width: 1,
  marginInline: '$xSmall',
  marginBlock: '$xSmall',
  backgroundColor: '$contrastLow',
});

const Spacer = styled('div', {
  flex: 1,
});

const ToolTipContent = styled('div', {
  maxWidth: '260px',
  fontSize: 'small',
  lineHeight: 'normal',
});

const GdprHint = styled('div', {
  paddingLeft: '$small',
});

export const Toolbar = ({
  id,
  hasCharactersCounter,
  gdprTooltip,
  imageUpload,
}: {
  id?: string;
  hasCharactersCounter?: boolean;
  gdprTooltip: string;
  imageUpload?: {
    onUploadImage: (
      fileOrData: File | string,
      opt: { onProgress?: (value: number) => void },
    ) => Promise<string>;
    allowedImageTypes?: { type: string; extension: string }[];
    i18n?: {
      imageUploadHeading?: string;
      uploadButtonText?: string;
      resetButtonText?: string;
      uploadErrorHeading?: string;
      uploadErrorDescription?: string;
      dropzoneDescription?: string;
      dragDescription?: string;
    };
  };
}) => {
  return (
    <Container>
      <BasicMarksToolbarButtons id={id} />
      <Seperator />
      <ListToolbarButtons id={id} />
      <Seperator />
      {imageUpload && (
        <>
          <ImageToolbarButtons id={id} {...imageUpload} />
          <Seperator />
        </>
      )}
      <HistoryToolbarButtons id={id} />

      <Spacer />

      {hasCharactersCounter && <CharacterCounter id={id} />}

      <GdprHint>
        <Tooltip content={<ToolTipContent>{gdprTooltip}</ToolTipContent>}>
          <PIcon name="information" />
        </Tooltip>
      </GdprHint>
    </Container>
  );
};
