import { PIcon } from '@porsche-design-system/components-react';
import { useRef } from 'react';
import DefaultSelect, {
  MenuListProps,
  StylesConfig,
  components,
  type DropdownIndicatorProps,
  type GroupBase,
  type OptionProps,
  type Props,
  type SelectInstance,
  type Theme,
} from 'react-select';
import DefaultAsyncSelect, { type AsyncProps } from 'react-select/async';
import DefaultCreatableSelect from 'react-select/creatable';
import { Label, Message } from '.';
import { theme as appTheme, styled } from '../stitches.config';

const Icon = styled(PIcon, {
  padding: '0 $small',
  color: '$disabled',
  cursor: 'pointer',
});

const OptionWrapper = styled('div', {
  display: 'flex',
  padding: '$small 12px',
  cursor: 'pointer',
  margin: '6px 0',
  borderRadius: '$small',
  transition: 'background-color .24s ease,color .24s ease',
  color: '$contrastHigh',

  variants: {
    isSelected: {
      true: {
        backgroundColor: '$backgroundSurface',
        color: '$black',
      },
    },
  },

  '&:hover': {
    backgroundColor: '$backgroundSurface',
    color: '$black',
  },
});

const OptionAppendix = styled('span', {
  color: '$contrastMedium',
  marginLeft: 'auto',
  textAlign: 'right',
});

const OptionIcon = styled('span', {
  minWidth: '40px',
  textAlign: 'right',
});

const RequiredInputFix = styled('input', {
  opacity: 0,
  width: '100%',
  height: 0,
  position: 'absolute',
});

const Container = styled('div', {
  position: 'relative',
});

export type DEFAULT_OPTION = {
  value: number;
  label: string;
  appendix?: string;
};

const getCustomStyles = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(): StylesConfig<Option, IsMulti, Group> => ({
  container: (provided, state) => ({
    ...provided,
    ...(state.selectProps.menuIsOpen && {
      outlineStyle: 'none',
      zIndex: 3, // PDS select arrow icon is hard-coded at 2
    }),
  }),

  indicatorSeparator: () => ({
    display: 'none',
  }),
  menuList: (provided) => ({
    ...provided,
    margin: '-8px 0',
  }),
  control: (provided, state) => ({
    ...provided,
    cursor: 'pointer',
    borderColor: state.isDisabled
      ? appTheme.colors.disabled.toString()
      : appTheme.colors.contrastMedium.toString(),
    borderWidth: 2,
    boxShadow: 'none',

    '&:hover': {
      borderColor: appTheme.colors.black.toString(),
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    background: appTheme.colors.contrastLow.toString(),
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    cursor: 'pointer',
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: '-3px',
    padding: '6px',
    boxShadow: 'none',
    border: `2px solid ${appTheme.colors.black.toString()}`,
    borderTop: `1px solid ${appTheme.colors.contrastMedium.toString()}`,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
  }),
});

const getCustomTheme = (theme: Theme): Theme => ({
  ...theme,
  borderRadius: 4,
  colors: {
    ...theme.colors,
    primary: appTheme.colors.black.toString(),
    primary25: appTheme.colors.backgroundSurface.toString(),
    neutral20: appTheme.colors.black.toString(),
    neutral10: appTheme.colors.disabled.toString(),
    neutral5: appTheme.colors.white.toString(),
  },
  spacing: {
    ...theme.spacing,
    controlHeight: 54,
    menuGutter: -1,
  },
});

export const DropdownIndicator = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  props: DropdownIndicatorProps<Option, IsMulti, Group>,
) => (
  <Icon
    // Color needs to be set to 'inherit', otherwise it is not possible to add a custom color
    color={props.isDisabled ? 'inherit' : 'primary'}
    name={`arrow-head-${props.selectProps.menuIsOpen ? 'up' : 'down'}`}
  />
);

const hasMaxOptions = <T extends object>(
  props: T,
): props is T & { maxOptions: number } => props && 'maxOptions' in props;

const MenuList = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>({
  children,
  ...props
}: MenuListProps<Option, IsMulti, Group>) => {
  return (
    <components.MenuList {...props}>
      {hasMaxOptions(props.selectProps) && Array.isArray(children)
        ? children.slice(0, props.selectProps?.maxOptions)
        : children}
    </components.MenuList>
  );
};

export interface SelectProps<
  Option = unknown,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends Props<Option, IsMulti, Group> {
  label?: string;
  state?: 'error' | 'info';
  message?: string;
  isRequired?: boolean;
}

export interface AsyncSelectProps<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
> extends AsyncProps<Option, IsMulti, Group> {
  label?: string;
  message?: string;
  isRequired?: boolean;
}

export const Option = <
  Option,
  IsMulti extends boolean,
  Group extends GroupBase<Option>,
>(
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: OptionProps<any, IsMulti, Group>,
) => {
  const { children, innerProps, ...rest } = props;
  const { appendix } = rest.data;

  return (
    <div {...innerProps}>
      <OptionWrapper isSelected={props.isSelected} role="option">
        <span>{children}</span>
        {appendix && <OptionAppendix>{appendix}</OptionAppendix>}
        {!props.isMulti && (
          <OptionIcon>{props.isSelected && <PIcon name="check" />}</OptionIcon>
        )}
      </OptionWrapper>
    </div>
  );
};

export const AsyncSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(
  props: AsyncSelectProps<Option, IsMulti, Group>,
): JSX.Element => {
  const selectRef = useRef<SelectInstance<Option, IsMulti, Group> | null>(null);

  return (
    <Container data-testid={props.inputId && `select-${props.inputId}`}>
      <Label
        label={props.label}
        isRequired={props.isRequired}
        isDisabled={props.isDisabled}
      />
      <DefaultAsyncSelect
        ref={selectRef}
        placeholder=""
        {...props}
        styles={getCustomStyles<Option, IsMulti, Group>()}
        components={{ DropdownIndicator, Option, ...props.components }}
        theme={getCustomTheme}
      />
      {props.isRequired ? (
        <RequiredInputFix
          tabIndex={-1}
          autoComplete="off"
          value={
            props.inputValue ??
            (props.value
              ? getSelectValue<Option, IsMulti, Group>(props)
              : undefined) ??
            ''
          }
          onChange={() => {
            // noop
          }}
          onFocus={() => selectRef.current?.focus?.()}
          required
        />
      ) : null}
      <Message value={props.message} />
    </Container>
  );
};

export const CreatableSelect = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  state,
  ...props
}: SelectProps<Option, IsMulti, Group> & {
  maxOptions?: number;
}): JSX.Element => {
  const selectRef = useRef<SelectInstance<Option, IsMulti, Group> | null>(null);
  return (
    <Container data-testid={props.inputId && `select-${props.inputId}`}>
      <Label
        label={props.label}
        isRequired={props.isRequired}
        isDisabled={props.isDisabled}
      />
      <DefaultCreatableSelect
        ref={selectRef}
        placeholder=""
        aria-invalid={state === 'error'}
        {...props}
        styles={getCustomStyles<Option, IsMulti, Group>()}
        components={{
          DropdownIndicator,
          Option,
          MenuList,
          ...props.components,
        }}
        theme={getCustomTheme}
      />
      {props.isRequired ? (
        <RequiredInputFix
          tabIndex={-1}
          autoComplete="off"
          value={
            props.inputValue ??
            (props.value
              ? getSelectValue<Option, IsMulti, Group>(props)
              : undefined) ??
            ''
          }
          onChange={() => {
            // noop
          }}
          onFocus={() => selectRef.current?.focus?.()}
          required
        />
      ) : null}
      <Message value={props.message} state={state} />
    </Container>
  );
};

export const Select = <
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>({
  state,
  ...props
}: SelectProps<Option, IsMulti, Group>): JSX.Element => {
  const selectRef = useRef<SelectInstance<Option, IsMulti, Group> | null>(null);
  return (
    <Container data-testid={props.inputId && `select-${props.inputId}`}>
      <Label
        label={props.label}
        isRequired={props.isRequired}
        isDisabled={props.isDisabled}
      />
      <DefaultSelect
        ref={selectRef}
        placeholder=""
        aria-invalid={state === 'error'}
        {...props}
        styles={getCustomStyles<Option, IsMulti, Group>()}
        components={{ DropdownIndicator, Option, ...props.components }}
        theme={getCustomTheme}
      />
      {props.isRequired ? (
        <RequiredInputFix
          tabIndex={-1}
          autoComplete="off"
          value={
            props.inputValue ??
            (props.value
              ? getSelectValue<Option, IsMulti, Group>(props)
              : undefined) ??
            ''
          }
          onChange={() => {
            // noop
          }}
          onFocus={() => selectRef.current?.focus?.()}
          required
        />
      ) : null}
      <Message value={props.message} state={state} />
    </Container>
  );
};

function getSelectValue<
  Option,
  IsMulti extends boolean = false,
  Group extends GroupBase<Option> = GroupBase<Option>,
>(props: SelectProps<Option, IsMulti, Group>) {
  return props.isMulti
    ? (props.value as Option[])
        ?.map(
          (v) =>
            props.getOptionValue?.(v) ??
            (v as { value: unknown }).value ??
            `${v}`,
        )
        .join(',')
    : props.getOptionValue?.(props.value as Option) ?? `${props.value}`;
}
