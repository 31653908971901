import { PHeading } from '@porsche-design-system/components-react';
import { DataTable } from '@porsche-kado/ui';
import { useParams } from '@tanstack/react-router';
import { createColumnHelper } from '@tanstack/react-table';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { NAMESPACES } from '../../../config/i18n';
import {
  OrganizationsQuery,
  useOrganizationsQuery,
  usePersonInfoQuery,
} from '../../../graphql';

export const RetailDesign = () => {
  const { t } = useTranslation(NAMESPACES);

  const { personId } = useParams({ from: '/admin/person-info/$personId/$app' });

  const { data: person, isLoading: isPersonInfoLoading } = usePersonInfoQuery(
    {
      id: +personId,
      includeDashboardAbility: false,
      includeFieldForceManagementAbility: false,
      includeGreenlandAbility: false,
      includeMysteryShoppingAbility: false,
      includeNetworkPlanningAbility: false,
      includePorscheStrategy2030Ability: false,
      includeRetailDesignAbility: true,
      includeSurveyAbility: false,
    },
    {
      select: (data) => data.person,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const additionalOrganizationsIds = person?.ability.retailDesign
    ?.filter(
      (ability) =>
        ability.action === 'read' && ability.subject === 'Organization',
    )
    .flatMap((ability) => ability.conditions?._id?.['$in'])
    .filter(Boolean) as number[];

  const {
    data: additionalOrganizations,
    isLoading: isAdditionalOrganizationsLoading,
  } = useOrganizationsQuery(
    {
      filter: { _id: { _in: additionalOrganizationsIds } },
    },
    {
      select: (data) => data.organizations.docs,
      enabled: !!additionalOrganizationsIds,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
    },
  );

  const columns = useMemo(() => {
    const columnHelper =
      createColumnHelper<OrganizationsQuery['organizations']['docs'][0]>();

    return [
      columnHelper.accessor('id', {
        cell: (data) => data.getValue(),
        header: () => t('common:id'),
      }),
      columnHelper.accessor('name', {
        cell: (data) => data.getValue(),
        header: () => t('common:name'),
      }),
    ];
  }, [t]);

  return (
    <>
      <PHeading size="small" tag="h3">
        {t('admin.personInfo.additionalOrganizations')}
      </PHeading>
      <DataTable
        isLoading={isPersonInfoLoading || isAdditionalOrganizationsLoading}
        caption={t('admin.personInfo.additionalOrganizations')}
        data={additionalOrganizations ?? []}
        columns={columns}
        idAccessor="name"
        pagination={{
          position: 'bottom',
        }}
        i18n={{
          filterLabel: (columnName) =>
            t('common:iconLabel.filter', { columnName }),
          optionAll: t('common:all'),
          buttonReset: t('common:action.reset'),
          buttonFilter: t('common:action.filter'),
          actionSearch: t('common:action.search'),
          noData: t('common:noData'),
        }}
      />
    </>
  );
};
