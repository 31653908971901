import { PIcon } from '@porsche-design-system/components-react';
import { styled } from '@stitches/react';
import { motion } from 'framer-motion';
import debounce from 'lodash/debounce';
import React, { ReactNode } from 'react';

const Wrapper = styled('div', {
  display: 'flex',
  alignItems: 'center',
  cursor: 'default',
});

const Header = styled('div', {
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',
  padding: 0,
});

const Actions = styled(motion.div, {
  overflow: 'hidden',
  display: 'grid',
  gridAutoColumns: 'auto',
  gridAutoFlow: 'column',
  gridGap: '$small',
  alignItems: 'center',
  marginRight: '$xSmall',
  padding: '$xSmall',
});

const Icon = styled(motion(PIcon), {
  cursor: 'pointer',
});

export const Fab = ({
  title,
  children,
}: {
  title: ReactNode;
  children: ReactNode;
}) => (
  <Header>
    {title}
    {children && <Button>{children}</Button>}
  </Header>
);

// TODO: use PButtonPure instead of PIcon
export const FabAction = styled(PIcon, {
  cursor: 'pointer',
  borderRadius: '$small',

  '&:hover': {
    backgroundColor: '$hover',
  },
});

const Button = ({
  onToggle,
  stayOpen = false,
  children,
  ...props
}: {
  onToggle?: (open: boolean) => void;
  stayOpen?: boolean;
  children: React.ReactNode;
}) => {
  const [isExpanded, setExpand] = React.useState(false);
  const [hasFocus, setHasFocus] = React.useState(false);

  // avoid closing the actions if mouseOver and click occur after each other
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const setExpandDebounced = React.useCallback(
    debounce(setExpand, 500, {
      leading: true,
      trailing: false,
    }),
    [setExpand],
  );

  const autoCollapse = React.useRef(0);

  React.useEffect(() => {
    let mounted = true;
    if (!hasFocus && !stayOpen) {
      autoCollapse.current = window.setTimeout(
        () => mounted && setExpand(false),
        1000,
      );
    } else if (autoCollapse.current) {
      window.clearTimeout(autoCollapse.current);
    }
    return () => {
      mounted = false;
    };
  }, [hasFocus, stayOpen]);

  const toggleRef = React.useRef(onToggle);
  toggleRef.current = onToggle;
  React.useEffect(() => {
    toggleRef.current?.(isExpanded);
  }, [isExpanded]);

  return (
    <Wrapper
      onPointerDown={(evt) => evt.stopPropagation()}
      onPointerEnter={() => {
        setHasFocus(true);
      }}
      onPointerLeave={() => {
        setHasFocus(false);
      }}
      {...props}
    >
      <Actions
        initial={{ width: '0px' }}
        animate={isExpanded ? 'expanded' : 'collapsed'}
        variants={{
          expanded: {
            width: 'auto',
            opacity: 1,
          },
          collapsed: {
            width: '0px',
            opacity: 0,
          },
        }}
        transition={{ ease: 'backInOut' }}
      >
        {children}
      </Actions>
      <Icon
        data-testid="fab-toggle"
        name="menu-dots-vertical"
        animate={{ rotate: isExpanded ? 90 : 0 }}
        onClick={() => setExpandDebounced((e) => !e)}
        onPointerOver={() => setExpandDebounced(true)}
      />
    </Wrapper>
  );
};
