import { PTextFieldWrapper } from '@porsche-design-system/components-react';
import { useRef } from 'react';
import { styled } from '../stitches.config';

export const ColorPickerInput = ({
  label,
  state,
  message,
  name,
  value,
  onChange,
}: {
  onChange?: (event: { target: { name?: string; value?: string } }) => void;
  onBlur?: () => void;
  value?: string;
  disabled?: boolean;
  name?: string;
  label?: string;
  state?: 'error' | 'none';
  message?: string;
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <div>
      <Container>
        <ColorInput
          ref={inputRef}
          type="color"
          name={name}
          value={value ?? ''}
          onChange={onChange}
          state={state}
        />

        <PTextFieldWrapper label={label} state={state} message={message}>
          <input
            type="text"
            value={`        ${value ?? ''}`}
            onFocus={() => inputRef.current?.showPicker()}
            onChange={() => {}}
          />
        </PTextFieldWrapper>
      </Container>
    </div>
  );
};

const Container = styled('div', {
  display: 'grid',
  gridTemplateColumns: 'auto 1fr',
  alignItems: 'center',
  gap: '$small',
  position: 'relative',
});

const ColorInput = styled('input', {
  position: 'absolute',
  left: '$small',
  bottom: '13%',
  width: '$large',
  height: '$large',

  variants: {
    state: {
      error: { bottom: '35%' },
      none: {},
    },
  },
});
