import axios from 'axios';
import {
  UploadReference,
  UploadType,
  UploadUrlsQuery,
  UploadUrlsQueryVariables,
} from '../graphql';
import { uploadUrls } from '../graphql/uploadDownload';
import { request } from './graphqlRequest';

export type FileToUpload = {
  id: string;
  file: File;
};

const fetchLinks = async (
  keys: string[],
  type: UploadType,
  reference: UploadReference,
) => {
  const result = await request<UploadUrlsQuery, UploadUrlsQueryVariables>(
    uploadUrls,
    {
      filenames: keys,
      type,
      reference,
    },
  )();

  return result.uploadUrls;
};

export type UploadProgress = {
  [key: string]: number;
};

export const uploader = async (
  files: FileToUpload[],
  type: UploadType,
  reference: UploadReference,
  onProgress: (progress: UploadProgress) => void,
  onFailure: (name: string, errorMessage: unknown) => void,
) => {
  const links = await fetchLinks(
    files.map((file) => file.id),
    type,
    reference,
  );

  if (links.urls.length === 0) {
    throw new Error('No upload links available');
  }

  const progress: UploadProgress = {};

  const uploads = files.map(async (file, index) => {
    const url = links.urls[index];

    try {
      await axios.put(url, file.file, {
        headers: {
          'Content-Type': file.file.type,
          'x-amz-server-side-encryption-aws-kms-key-id': links.kmsKeyId,
          'x-amz-server-side-encryption-bucket-key-enabled': 'true',
          'x-amz-server-side-encryption': 'aws:kms',
        },
        onUploadProgress: (progressEvent: {
          loaded: number;
          total: number;
        }) => {
          progress[file.id] =
            (progressEvent.loaded / progressEvent.total) * 100;
          onProgress(progress);
        },
      });

      return file;
    } catch (error) {
      onFailure(file.file.name, error);
    }
  });

  return Promise.all(uploads);
};

export const getType = (dataString: string) => {
  const matches = dataString.match(/^data:([A-Za-z-+/@]+);base64,(.+)$/);
  if (!matches || matches.length !== 3) return undefined;
  return matches[1];
};
