import {
  PHeading,
  PTextList,
  PTextListItem,
} from '@porsche-design-system/components-react';
import { Spacer } from '@porsche-kado/ui';
import { useTranslation } from 'react-i18next';
import { Paragraph } from '../components';

export const PrivacyPolicy = () => {
  const {
    i18n: { language },
  } = useTranslation();

  return language === 'de-DE' ? <German /> : <English />;
};

const German = () => (
  <>
    <PHeading role="heading" size="large" aria-level={1} tag="h1">
      Datenschutzerklärung
    </PHeading>

    <Spacer mb="$medium" />

    <PHeading size="medium" tag="h2">
      KADO
    </PHeading>
    <Paragraph>
      Wir, die Dr. Ing. h.c. F. Porsche AG (nachfolgend „wir“ oder „Porsche
      AG“), freuen uns über Ihr Interesse an KADO (nachfolgend das
      „Online-Angebot“). Wir nehmen den Schutz Ihrer personenbezogenen Daten
      sehr ernst. Die Verarbeitung Ihrer personenbezogenen Daten erfolgt
      ausschließlich im Rahmen der gesetzlichen Bestimmungen des
      Datenschutzrechts, insbesondere der Datenschutzgrundverordnung
      (nachfolgend „DSGVO“). Mit dieser Datenschutzerklärung informieren wir Sie
      über die Verarbeitung Ihrer personenbezogenen Daten und über Ihre Rechte
      als betroffene Person im Rahmen des Online-Angebots. Informationen zur
      Verarbeitung personenbezogener Daten in anderen Bereichen entnehmen Sie
      bitte der jeweiligen spezifischen Datenschutzerklärung.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      1. Verantwortlicher und Datenschutzbeauftragter
    </PHeading>
    <Paragraph>
      Verantwortlich für die Datenverarbeitung im Sinne der Datenschutzgesetze
      ist die:
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG <br />
      Porscheplatz 1<br />
      70435 Stuttgart
      <br />
      Germany
      <br />
      Phone: +49 (0) 711 911-0
      <br />
      Email: info@porsche.de
    </Paragraph>
    <Paragraph>
      Sollten Sie Fragen oder Anregungen zum Datenschutz haben, können Sie sich
      gerne an uns wenden. Unseren Datenschutzbeauftragten erreichen Sie wie
      folgt:
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG
      <br />
      Beauftragter für Datenschutz <br />
      Porscheplatz 1<br />
      70435 Stuttgart
      <br />
      Germany
      <br />
    </Paragraph>
    <Paragraph>
      Kontakt:{' '}
      <a href="https://www.porsche.com/privacy-contact/">
        https://www.porsche.com/privacy-contact/
      </a>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      2. Gegenstand des Datenschutzes
    </PHeading>
    <Paragraph>
      Gegenstand des Datenschutzes ist der Schutz personenbezogener Daten. Diese
      sind alle Informationen, die sich auf eine identifizierte oder
      identifizierbare natürliche Person (sog. betroffene Person) beziehen.
      Hierunter fallen Angaben wie z.B. Name, Post-Adresse, E-Mail-Adresse oder
      Telefonnummer, aber auch sonstige Informationen, die im Rahmen der Nutzung
      des Online-Angebots entstehen, insbesondere Angaben über Beginn, Ende und
      Umfang der Nutzung sowie die Übermittlung Ihrer IP-Adresse.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      3. Zwecke und Rechtsgrundlagen der Datenverarbeitung
    </PHeading>
    <Paragraph>
      Nachfolgend erhalten Sie einen Überblick über Zwecke und Rechtsgrundlagen
      der Datenverarbeitung im Rahmen des Online-Angebots. Wir verarbeiten
      personenbezogene Daten jedenfalls entsprechend der rechtlichen Vorgaben,
      auch wenn im Einzelfall eine andere Rechtsgrundlage einschlägig sein
      sollte als im Folgenden angegeben.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.1 Erbringung des Online-Angebots
    </PHeading>
    <Paragraph>
      Wir verarbeiten Ihre personenbezogenen Daten, soweit dies für die
      Erbringung des Online-Angebots erforderlich ist. Die Datenverarbeitung
      erfolgt auf Basis des Artikels 6 Absatz 1 Buchstabe b) DSGVO bzw. § 26
      Abs. 1 Bundesdatenschutzgesetz, soweit Sie unser Vertragspartner bzw.
      Mitarbeiter sind, bzw. auf Basis des Artikels 6 Absatz 1 Buchstabe f)
      DSGVO, soweit wir das berechtigte Interesse verfolgen, Sie bei Ihren
      Aufgaben zu unterstützen.
    </Paragraph>
    <Paragraph>
      Sie können im Rahmen des Online-Angebots Termine und Aufgaben (Action
      Items) planen, dokumentieren und hinterlegte Action Items durchsuchen. Ihr
      persönliches Nutzerprofil basiert auf Ihrem Profil für das Porsche Partner
      Network (PPN). Sie können sich selbst oder Anderen Action Items zuweisen
      und deren Bearbeitung und Status nachverfolgen.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.2 Erfüllung rechtlicher Verpflichtungen{' '}
    </PHeading>
    <Paragraph>
      Wir verarbeiten Ihre personenbezogenen Daten, um rechtliche
      Verpflichtungen einzuhalten, denen wir unterliegen. Die Datenverarbeitung
      erfolgt auf Basis des Artikels 6 Absatz 1 Buchstabe c) DSGVO. Die
      Verpflichtungen können sich z.B. aus dem Handels-, Steuer-, Geldwäsche-,
      Finanz- oder Strafrecht ergeben. Die Zwecke der Verarbeitung ergeben sich
      aus der jeweiligen rechtlichen Verpflichtung; die Verarbeitung dient in
      der Regel dem Zweck, staatlichen Kontroll- und Auskunftspflichten
      nachzukommen.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.3 Wahrung berechtigter Interessen
    </PHeading>
    <Paragraph>
      Wir verarbeiten Ihre personenbezogenen Daten darüber hinaus zur Wahrung
      berechtigter Interessen von uns oder Dritten, soweit nicht Ihre
      Interessen, die den Schutz Ihrer personenbezogener Daten erfordern,
      überwiegen. Die Datenverarbeitung erfolgt auf Basis des Artikels 6 Absatz
      1 Buchstabe f) DSGVO. Die Verarbeitung zur Wahrung berechtigter Interesse
      erfolgt zu folgenden Zwecken bzw. zur Wahrung folgender Interessen.
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Bearbeitung von außervertraglichen Anfragen und Anliegen;
        </PTextListItem>
        <PTextListItem>
          Sicherstellung rechtskonformen Handelns, Prävention von und Schutz vor
          Rechtsverstößen (insb. Straftaten), Geltendmachung von und
          Verteidigung gegen rechtliche Ansprüche, interne und externe
          Compliance-Maßnahmen;
        </PTextListItem>
        <PTextListItem>
          Gewährleistung von Verfügbarkeit, Betrieb und Sicherheit technischer
          Systeme sowie technisches Datenmanagement;
        </PTextListItem>
        <PTextListItem>
          Beantwortung und Auswertung von Kontaktanfragen und Rückmeldungen.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <Paragraph>
      Wenn Sie das Online-Angebot aufrufen, werden Daten in Bezug auf Ihr
      Endgerät und Ihre Nutzung des Online-Angebots verarbeitet und in einer
      sog. Log-Datei gespeichert. Dies betrifft insbesondere technische Daten
      wie Datum und Uhrzeit des Zugriffs, Dauer des Besuchs, Art des Endgeräts,
      verwendetes Betriebssystem, genutzte Funktionen, Menge der gesendeten
      Daten, IP-Adresse und Referrer-URL. Diese Daten verarbeiten wir zur
      Sicherstellung des technischen Betriebs sowie zur Ermittlung und
      Beseitigung von Störungen. Wir verfolgen dabei das Interesse, die
      technische Funktionsfähigkeit dauerhaft sicherzustellen. Wir verwenden
      diese Daten nicht zu dem Zweck, Rückschlüsse auf Ihre Person zu ziehen.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.4 Einwilligung{' '}
    </PHeading>
    <Paragraph>
      Wir verarbeiten Ihre personenbezogenen Daten auf Grundlage entsprechender
      Einwilligung. Die Datenverarbeitung erfolgt auf Basis des Artikels 6
      Absatz 1 Buchstabe a) DSGVO. Sollten Sie eine Einwilligung erteilen, ist
      diese stets zweckgebunden; die Zwecke der Verarbeitung ergeben sich
      jeweils aus dem Inhalt Ihrer Einwilligungserklärung. Eine erteilte
      Einwilligung können Sie jederzeit widerrufen, ohne dass die Rechtmäßigkeit
      der aufgrund der Einwilligung bis zum Widerruf erfolgten Verarbeitung
      hiervon berührt wird.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.5 Zweckänderung
    </PHeading>
    <Paragraph>
      Soweit wir Ihre personenbezogenen Daten jenseits einer entsprechenden
      Einwilligung oder einer zwingenden Rechtsgrundlage zu einem anderen Zweck
      verarbeiten als zu demjenigen, zu dem die Daten erhoben wurden,
      berücksichtigen wir gemäß Artikel 6 Absatz 4 DSGVO die Vereinbarkeit des
      ursprünglichen und des nunmehr verfolgten Zwecks, die Art der
      personenbezogenen Daten, die möglichen Folgen der weiteren Verarbeitung
      für Sie und die Garantien zum Schutz der personenbezogenen Daten.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.6 Profiling
    </PHeading>
    <Paragraph>
      Wir führen keine automatisierte Entscheidungsfindung bzw. kein Profiling
      gemäß Artikel 22 DSGVO durch. Eine Profilbildung erfolgt nur zur Wahrung
      unserer berechtigten Interessen wie oben beschrieben.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      4. Zugriffsberechtigungen im Endgerät
    </PHeading>
    <Paragraph>
      Einige Funktionen unseres Online-Angebots erfordern die Erteilung der
      Berechtigung zum Zugriff auf Ihr Endgerät (z.B. Zugriff auf
      Standortdaten). Die Erteilung der Berechtigungen ist freiwillig. Sollten
      Sie jedoch die entsprechenden Funktionen nutzen wollen, ist die Erteilung
      der entsprechenden Berechtigungen erforderlich, da Sie andernfalls diese
      Funktionen nicht nutzen können. Die Berechtigungen bleiben so lange aktiv,
      wie Sie diese nicht in ihrem Endgerät durch die Deaktivierung der
      jeweiligen Einstellung wieder zurückgesetzt haben.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      5. Cookies und vergleichbare Technologien
    </PHeading>
    <Paragraph>
      Wir verwenden im Rahmen des Online-Angebots Cookies und vergleichbare
      Technologien, die dazu dienen, mit Ihrem Endgerät zu kommunizieren und
      gespeicherte Informationen auszutauschen (nachfolgend insgesamt
      „Cookies“). Diese Cookies dienen in erster Linie dazu, die Funktionen des
      Online-Angebots nutzbar zu machen. Allgemeine Beispiele, in denen der
      Einsatz von Cookies in diesem Sinne technisch erforderlich ist, sind die
      Speicherung einer Sprachauswahl, von Login-Daten oder einer Einkaufs- bzw.
      Merkliste. Dementsprechend können technisch erforderliche Cookies von uns
      eingesetzt werden, um die unter Ziffer 3.1 beschriebenen Verarbeitungen zu
      ermöglichen und den ordnungsgemäßen und sicheren Betrieb des
      Online-Angebots zu gewährleisten. Die Datenverarbeitung erfolgt dann auf
      Basis des Artikels 6 Absatz 1 Buchstabe b) und f) DSGVO, da sie
      erforderlich ist, um die von Ihnen gewählten Funktionen umzusetzen bzw. um
      unser berechtigtes Interesse an der Funktionsfähigkeit des Online-Angebots
      zu wahren.
    </Paragraph>
    <Paragraph>
      Soweit wir darüber hinaus Cookies einsetzen sollten, um die Nutzung des
      Online-Angebots zu analysieren und zielgerichtet auf Ihre Interessen
      ausrichten zu können und ggf. auch, um Ihnen interessenbasierte Inhalte
      und Anzeigen bereitstellen zu können, erfolgt dies ausschließlich auf
      Basis Ihrer freiwilligen Einwilligung gemäß Artikel 6 Absatz 1 Buchstabe
      a) DSGVO. Sie haben dann die Möglichkeit, über das Einwilligungsmanagement
      im Rahmen des Online-Angebots Ihre entsprechenden Einstellungen
      vorzunehmen. Sie können eine hierüber erteilte Einwilligung jederzeit mit
      Wirkung für die Zukunft widerrufen. Weitere Informationen zu den Cookies
      und zu deren Funktion im Einzelnen sowie zu Einstellungs- und
      Widerrufsmöglichkeiten erhalten Sie direkt in den entsprechenden Bereichen
      des Einwilligungsmanagements. Bitte beachten Sie, dass wir das
      Einwilligungsmanagement im Rahmen des Online-Angebots grundsätzlich nur
      zur Verfügung stellen, wenn über die oben genannten technisch
      erforderlichen Cookies hinaus einwilligungsbasiert Cookies eingesetzt
      werden sollen.
    </Paragraph>
    <Paragraph>
      Wenn Sie den Einsatz von Cookies insgesamt nicht wünschen, können Sie auch
      deren Speicherung ggf. durch entsprechende Einstellungen Ihres Endgeräts
      verhindern. Gespeicherte Cookies können Sie in den Systemeinstellungen
      Ihres Endgeräts jederzeit löschen. Bitte beachten Sie, dass die
      Blockierung bestimmter Arten von Cookies zu einer beeinträchtigten Nutzung
      unseres Online-Angebots führen kann.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      6. Quellen und Datenkategorien bei Dritterhebung{' '}
    </PHeading>
    <Paragraph>
      Wir verarbeiten auch personenbezogene Daten, die wir von Dritten oder aus
      öffentlich zugänglichen Quellen erhalten. Nachfolgend finden Sie eine
      Übersicht über die entsprechenden Quellen und die aus diesen Quellen
      erhaltenen Datenkategorien.
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Konzerngesellschaften, Porsche-Händler und Service-Betriebe: Daten zu
          Terminen und Aufgaben (Action Items), die durch andere Nutzer des
          Online-Angebots eingegeben werden.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      7. Empfänger personenbezogener Daten
    </PHeading>
    <Paragraph>
      Innerhalb unseres Unternehmens haben nur diejenigen Personen Zugriff auf
      Ihre personenbezogenen Daten, die diese für die jeweils genannten Zwecke
      benötigen. An externe Empfänger geben Ihre personenbezogenen Daten nur
      weiter, wenn hierfür eine gesetzliche Erlaubnis besteht oder uns Ihre
      Einwilligung vorliegt. Nachfolgend finden Sie eine Übersicht über die
      entsprechenden Empfänger:
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Auftragsverarbeiter: Konzerngesellschaften oder externe Dienstleister,
          beispielsweise in den Bereichen der technischen Infrastruktur und
          Wartung, die sorgfältig ausgewählt und überprüft werden. Die
          Auftragsverarbeiter dürfen die Daten ausschließlich nach unseren
          Weisungen verwenden.
        </PTextListItem>
        <PTextListItem>
          Öffentliche Stellen: Behörden und staatliche Institutionen, wie z.B.
          Steuerbehörden, Staatsanwaltschaften oder Gerichte, an die wir z.B.
          zur Erfüllung rechtlicher Verpflichtungen oder zur Wahrung
          berechtigter Interessen personenbezogene Daten übermitteln (müssen).
        </PTextListItem>
        <PTextListItem>
          Private Stellen: Konzerngesellschaften, Porsche-Händler und
          Service-Betriebe, Kooperationspartner, (nicht weisungsgebundene)
          Dienstleister oder beauftragte Personen wie z.B. Porsche-Zentren und
          Porsche-Service-Zentren, Finanzierungsbanken, Auskunfteien oder
          Transportdienstleister.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      8. Datenverarbeitung in Drittländern
    </PHeading>
    <Paragraph>
      Findet eine Datenübermittlung an Stellen statt, deren Sitz oder deren Ort
      der Datenverarbeitung nicht in einem Mitgliedstaat der Europäischen Union,
      einem anderen Vertragsstaat des Abkommens über den Europäischen
      Wirtschaftsraum oder einem Staat, für den durch einen Beschluss der
      Europäischen Kommission ein angemessenes Datenschutzniveau festgestellt
      wurde, gelegen ist, stellen wir vor der Weitergabe sicher, dass die
      Datenübermittlung entweder durch einen gesetzlichen Erlaubnistatbestand
      gedeckt ist, Garantien für ein angemessenes Datenschutzniveau in Bezug auf
      die Datenübermittlung bestehen (z.B. durch die Vereinbarung vertraglicher
      Gewährleistungen, behördlich anerkannte Regelungen oder verbindliche
      interne Datenschutzvorschriften beim Empfänger) oder Sie Ihre Einwilligung
      in die Datenübermittlung erteilt haben.
    </Paragraph>
    <Paragraph>
      Soweit die Datenübermittlung auf Grundlage von Artikel 46, 47 oder 49
      Absatz 1 Unterabsatz 2 DSGVO erfolgt, können Sie bei uns eine Kopie der
      Garantien für das Bestehen eines angemessenen Datenschutzniveaus in Bezug
      auf die Datenübermittlung oder einen Hinweis auf die Verfügbarkeit einer
      Kopie der Garantien erhalten. Bitte nutzen Sie hierfür die Angaben unter
      Ziffer 1.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      9. Speicherdauer, Löschung
    </PHeading>
    <Paragraph>
      Wir speichern Ihre personenbezogenen Daten, wenn hierfür eine gesetzliche
      Erlaubnis besteht, nur so lange wie zur Erreichung der verfolgten Zwecke
      erforderlich bzw. solange Sie die Einwilligung nicht widerrufen haben. Im
      Falle eines Widerspruchs gegen die Verarbeitung löschen wir Ihre
      personenbezogenen Daten, es sei denn, die Weiterverarbeitung ist nach den
      gesetzlichen Bestimmungen weiterhin erlaubt. Wir löschen Ihre
      personenbezogenen Daten auch dann, wenn wir hierzu aus anderen rechtlichen
      Gründen verpflichtet sind. Unter Anwendung dieser allgemeinen Grundsätze
      löschen wir Ihre personenbezogenen Daten in der Regel unverzüglich
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          nach Wegfall der rechtlichen Grundlage und sofern keine anderweitige
          Rechtsgrundlage (z.B. handels- und steuerrechtliche
          Aufbewahrungsfristen) eingreift. Falls Letzteres zutrifft, löschen wir
          die Daten nach Wegfall der anderweitigen Rechtsgrundlage;
        </PTextListItem>
        <PTextListItem>
          wenn Ihre personenbezogenen Daten für die von uns verfolgten Zwecke
          nicht mehr erforderlich sind und keine anderweitige Rechtsgrundlage
          (z.B. handels- und steuerrechtliche Aufbewahrungsfristen) eingreift.
          Falls Letzteres zutrifft, löschen wir die Daten nach Wegfall der
          anderweitigen Rechtsgrundlage.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      10. Betroffenenrechte
    </PHeading>
    <Paragraph>
      <strong>Auskunftsrecht:</strong> Sie haben das Recht, Auskunft über die
      von uns zu Ihrer Person gespeicherten Daten zu erhalten.
    </Paragraph>
    <Paragraph>
      <strong>Berichtigungs- und Löschungsrecht:</strong> Sie können von uns die
      Berichtigung falscher Daten und - soweit die gesetzlichen Voraussetzungen
      erfüllt sind - Löschung Ihrer Daten verlangen.
    </Paragraph>
    <Paragraph>
      <strong>Einschränkung der Verarbeitung:</strong> Sie können von uns -
      soweit die gesetzlichen Voraussetzungen erfüllt sind - verlangen, dass wir
      die Verarbeitung Ihrer Daten einschränken.
    </Paragraph>
    <Paragraph>
      <strong>Datenübertragbarkeit:</strong> Sollten Sie uns Daten auf Basis
      eines Vertrages oder einer Einwilligung bereitgestellt haben, so können
      Sie bei Vorliegen der gesetzlichen Voraussetzungen verlangen, dass Sie die
      von Ihnen bereitgestellten Daten in einem strukturierten, gängigen und
      maschinenlesbaren Format erhalten oder dass wir diese an einen anderen
      Verantwortlichen übermitteln.
    </Paragraph>
    <Paragraph>
      <strong>Widerspruch:</strong> Sie haben das Recht, aus Gründen, die sich
      aus ihrer besonderen Situation ergeben, jederzeit der Datenverarbeitung
      durch uns zu widersprechen, soweit diese auf der Wahrung berechtigter
      Interesse beruht. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen,
      werden wir die Verarbeitung einstellen, es sei denn, wir können zwingende
      schutzwürdige Gründe für die Weiterverarbeitung nachweisen, welche Ihre
      Rechte und Interessen überwiegen.
    </Paragraph>
    <Paragraph>
      <strong>Widerspruch gegen Direktwerbung:</strong> Sofern wir Ihre
      personenbezogenen Daten verarbeiten, um Direktwerbung zu betreiben, haben
      Sie das Recht, jederzeit der Datenverarbeitung durch uns zu diesem Zweck
      zu widersprechen. Sofern Sie von Ihrem Widerspruchsrecht Gebrauch machen,
      werden wir die Verarbeitung zu diesem Zweck einstellen.
    </Paragraph>
    <Paragraph>
      <strong>Widerruf der Einwilligung:</strong> Sofern Sie uns eine
      Einwilligung in die Verarbeitung Ihrer personenbezogenen Daten erteilt
      haben, können Sie diese jederzeit mit Wirkung für die Zukunft widerrufen.
      Die Rechtmäßigkeit der Verarbeitung Ihrer Daten bis zum Widerruf bleibt
      hiervon unberührt.
    </Paragraph>
    <Paragraph>
      <strong>Beschwerderecht bei der Aufsichtsbehörde:</strong> Sie können
      zudem eine Beschwerde bei der zuständigen Aufsichtsbehörde einreichen,
      wenn Sie der Meinung sind, dass die Verarbeitung Ihrer Daten gegen
      geltendes Recht verstößt. Sie können sich hierzu an die Aufsichtsbehörde
      wenden, die für Ihren Wohnort bzw. Ihr Land zuständig ist oder an die für
      uns zuständige Aufsichtsbehörde.
    </Paragraph>
    <Paragraph>
      <strong>Ihr Kontakt zu uns und die Ausübung Ihrer Rechte:</strong> Des
      Weiteren können Sie sich bei Fragen zur Verarbeitung Ihrer
      personenbezogenen Daten und zu Ihren Betroffenenrechten unentgeltlich mit
      uns in Verbindung setzen. Wenden Sie sich bitte an
      https://www.porsche.com/privacy-contact/ oder postalisch an die oben unter
      Ziffer 1 angegebene Anschrift. Bitte stellen Sie dabei sicher, dass uns
      eine eindeutige Identifizierung Ihrer Person möglich ist. Beim Widerruf
      der Einwilligung können Sie alternativ auch den Kontaktweg wählen, den Sie
      bei der Abgabe der Einwilligung verwendet haben.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      11. Stand
    </PHeading>
    <Paragraph>
      Es gilt die aktuellste Version dieser Datenschutzerklärung. Stand
      18.01.2021.
    </Paragraph>
  </>
);

const English = () => (
  <>
    <PHeading role="heading" size="large" aria-level={1} tag="h1">
      Privacy Policy
    </PHeading>

    <Spacer mb="$medium" />

    <PHeading size="medium" tag="h2">
      KADO
    </PHeading>
    <Paragraph>
      We, Dr. Ing. h.c. F. Porsche AG (hereinafter referred to as &quot;we&quot;
      or &quot;Porsche AG&quot;), are happy about your interest in KADO
      (hereinafter referred to as the &quot;Online Service&quot;). We take the
      protection of your personal data very seriously. Your personal data will
      be processed exclusively in accordance with the statutory provisions of
      data protection law, in particular the General Data Protection Regulation
      (hereinafter referred to as &quot;GDPR&quot;). With this Privacy Policy,
      we inform you about the processing of your personal data and about your
      rights as a data subject as affected in connection with the Online
      Service. For information on the processing of personal data in other
      areas, please refer to the respective specific privacy policies.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      1. Controller and data protection officer
    </PHeading>
    <Paragraph>
      Responsible for the data processing as controller in terms of data
      protection law is:
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG
      <br />
      Porscheplatz 1<br />
      70435 Stuttgart
      <br />
      Germany
      <br />
      Phone: +49 (0) 711 911-0
      <br />
      Email: info@porsche.de
    </Paragraph>
    <Paragraph>
      If you have any questions or suggestions regarding data protection, please
      feel free to contact us. You can reach our data protection officer as
      follows:
    </Paragraph>
    <Paragraph>
      Dr. Ing. h.c. F. Porsche AG
      <br />
      Data Protection Officer <br />
      Porscheplatz 1<br />
      70435 Stuttgart
      <br />
      Germany
      <br />
    </Paragraph>
    <Paragraph>
      Contact:{' '}
      <a href="https://www.porsche.com/privacy-contact/">
        https://www.porsche.com/privacy-contact/
      </a>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      2. Subject of data protection
    </PHeading>
    <Paragraph>
      The subject of data protection is the protection of personal data. This is
      all information relating to an identified or identifiable natural person
      (so-called data subject). This includes information such as name, postal
      address, email address or telephone number, but also other information
      that may be generated when using the Online Service, in particular
      information about the beginning, end and extent of use as well as the
      transmission of your IP address.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      3. Purposes and legal basis of data processing
    </PHeading>
    <Paragraph>
      In the following, you will find an overview of the purposes and legal
      basis of data processing in connection with the Online Service. In any
      case, we process personal data in accordance with the legal requirements,
      even if in individual cases a different legal basis should be relevant
      than that stated below.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.1 Provision of the Online Service
    </PHeading>
    <Paragraph>
      We process your personal data to the extent this is necessary for the
      provision of the Online Service. The data processing is based on Article 6
      paragraph 1 letter b) GDPR or Section 26 par. 1 German Data Protection Act
      (Bundesdatenschutzgesetz) insofar as you are our contractual partner or
      employee, or is based on Article 6 paragraph 1 letter f) GDPR insofar as
      we pursue the legitimate interest of supporting you in your tasks.
    </Paragraph>
    <Paragraph>
      Within the scope of the Online Service, you can plan and document
      appointments and tasks (Action Items) and search stored Action Items. Your
      personal user profile is based on your profile for the Porsche Partner
      Network (PPN). You can assign Action Items to yourself or other and track
      their processing and status.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.2 Compliance with legal obligations
    </PHeading>
    <Paragraph>
      We process your personal data to comply with legal obligations to which we
      are subject. The data processing is based on Article 6 paragraph 1 letter
      c) GDPR. These obligations may arise, for example, from commercial, tax,
      money laundering, financial or criminal law. The purposes of the
      processing result from the respective legal obligation; as a rule, the
      processing serves the purpose of complying with state control and
      information obligations.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.3 Safeguarding of legitimate interests
    </PHeading>
    <Paragraph>
      We also process your personal data to pursue the legitimate interests of
      ourselves or third parties, unless your rights, which require the
      protection of your personal data, outweigh these interests. The data
      processing is based on Article 6 paragraph 1 letter f) GDPR. The
      processing to safeguard legitimate interests is carried out for the
      following purposes or to safeguard the following interests.
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Handling of non-contractual inquiries and concerns;
        </PTextListItem>
        <PTextListItem>
          Ensuring legally compliant actions, prevention of and protection
          against legal violations (especially criminal offences), assertion of
          and defence against legal claims, internal and external compliance
          measures;
        </PTextListItem>
        <PTextListItem>
          Ensuring availability, operation and security of technical systems as
          well as technical data management;
        </PTextListItem>
        <PTextListItem>
          Answering and evaluation of contact requests and feedback.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <Paragraph>
      When you call up the Online Service, data relating to your end device and
      your use of the online offer are processed and stored in a so-called log
      file. This concerns in particular technical data such as date and time of
      access, duration of the visit, type of terminal device, operating system
      used, functions used, amount of data sent, IP address and referrer URL. We
      process this data to ensure technical operation and to determine and
      eliminate faults. In doing so, we pursue the interest of permanently
      ensuring technical operability. We do not use this data for the purpose of
      drawing conclusions about your person.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.4 Consent
    </PHeading>
    <Paragraph>
      We process your personal data on the basis of corresponding consent. The
      data processing is based on Article 6 paragraph 1 letter a) GDPR. If you
      give your consent, it is always for a specific purpose; the purposes of
      processing are determined by the content of your declaration of consent.
      You may revoke any consent you have given at any time, without affecting
      the legality of the processing that has taken place on the basis of the
      consent until revocation.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.5 Change of purpose
    </PHeading>
    <Paragraph>
      If we process your personal data for a purpose other than that for which
      the data was collected, beyond the scope of a corresponding consent or a
      mandatory legal basis, we will take into account, in accordance with
      Article 6 paragraph 4 GDPR, the compatibility of the original and the now
      pursued purpose, the nature of the personal data, the possible
      consequences of further processing for you and the guarantees for the
      protection of the personal data.
    </Paragraph>
    <PHeading size="small" tag="h3">
      3.6 Profiling
    </PHeading>
    <Paragraph>
      We do not carry out automated decision making or profiling in accordance
      with Article 22 GDPR. Profiling is only carried out to protect our
      legitimate interests as described above.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      4. Access authorizations in the end device
    </PHeading>
    <Paragraph>
      Some functions of the Online Service require the granting of authorization
      to access your end device (e.g. access to location data). The granting of
      these authorizations is voluntary. However, if you wish to use the
      corresponding functions, you must grant the appropriate authorizations,
      otherwise you will not be able to use these functions. The permissions
      remain active as long as you have not reset them in your device by
      deactivating the respective setting.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      5. Cookies and comparable technologies
    </PHeading>
    <Paragraph>
      We use cookies and comparable technologies in connection with the Online
      Service which serve to communicate with your end device and exchange
      stored information (hereinafter collectively referred to as
      &quot;Cookies&quot;). These Cookies are primarily used to make the
      functions of the Online Service usable. General examples in which the use
      of Cookies is technically required in this sense are the storage of a
      language selection, login data or a shopping or watch list. Accordingly,
      technically required Cookies may be used by us to enable the processing
      described in section 3.1 and to ensure the proper and secure operation of
      the Online Service. The data processing is then carried out on the basis
      of Article 6 paragraph 1 letters b) and f) GDPR, as it is necessary to
      implement the functions you have selected or to protect our legitimate
      interest in the functionality of the Online Service.
    </Paragraph>
    <Paragraph>
      Insofar as we should also use Cookies in order to analyse the use of the
      Online Service and to be able to target it to your interests and, if
      necessary, to provide you with interestbased content and advertisements,
      this is done exclusively on the basis of your voluntary consent in
      accordance with Article 6 paragraph 1 letter a) GDPR. You will then have
      the opportunity to make the appropriate settings within the Online Service
      via the consent management. You may revoke any consent you have given at
      any time with effect for the future. Further information on Cookies and
      their function in detail as well as on setting and revocation options can
      be found directly in the corresponding areas of the consent management.
      Please note that we only make available the consent management in the
      context of the Online Service if, in addition to the above-mentioned
      technically required Cookies, consent based Cookies are to be used.
    </Paragraph>
    <Paragraph>
      If you do not wish to use Cookies in general, you can also prevent their
      storage by adjusting the settings of your end device accordingly. Stored
      Cookies can be deleted at any time in the system settings of your terminal
      device. Please note that blocking certain types of Cookies can lead to
      impaired use of the Online Service.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      6. Sources and categories of data in case of third party collection
    </PHeading>
    <Paragraph>
      We also process personal data that we receive from third parties or from
      publicly accessible sources. Below you will find an overview of the
      corresponding sources and the categories of data obtained from these
      sources.
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Group companies, Porsche dealers and service companies: Data regarding
          appointments and tasks (Action Items) that have been entered by other
          users of the Online Service.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      7. Recipients of personal data
    </PHeading>
    <Paragraph>
      Within our company, only those persons who need your personal data for the
      respective purposes mentioned have access to it. Your personal data will
      only be passed on to external recipients if we have legal permission to do
      so or have your consent. Below you will find an overview of the
      corresponding recipients:
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          Commissioned processors: Group companies or external service
          providers, for example in the areas of technical infrastructure and
          maintenance, which are carefully selected and reviewed. The processors
          may only use the data in accordance with our instructions.
        </PTextListItem>
        <PTextListItem>
          Public bodies: Authorities and state institutions, such as tax
          authorities, public prosecutors&apos; offices or courts, to which we
          (must) transfer personal data, e.g. to fulfil legal obligations or to
          protect legitimate interests.
        </PTextListItem>
        <PTextListItem>
          Private bodies: Group companies, Porsche dealers and service
          companies, cooperation partners, (non-processor) service providers or
          commissioned persons such as Porsche Centres and Porsche Service
          Centres, financing banks, credit agencies or transport service
          providers.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      8. Data processing in third countries
    </PHeading>
    <Paragraph>
      If a data transfer takes place to entities whose registered office or
      place of data processing is not located in a member state of the European
      Union, another state party to the Agreement on the European Economic Area
      or a state for which an adequate level of data protection has been
      determined by a decision of the European Commission, we will ensure prior
      to the transfer that either the data transfer is covered by a statutory
      permit, that guarantees for an adequate level of data protection with
      regard to the data transfer are in place (e.g., through the agreement of
      contractual warranties, officially recognized regulations or binding
      internal data protection regulations at the recipient), or that you have
      given your consent to the data transfer.
    </Paragraph>
    <Paragraph>
      If the data is transferred on the basis of Articles 46, 47 or 49 paragraph
      1, subparagraph 2 GDPR, you can obtain from us a copy or reference to the
      availability of the guarantees for an adequate level of data protection in
      relation to the data transfer. Please use the information provided under
      Section 1.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      9. Storage duration, erasure of data
    </PHeading>
    <Paragraph>
      We store your personal data, if there is legal permission to do so, only
      as long as necessary to achieve the intended purposes or as long as you
      have not revoked your consent. In the event of an objection to processing,
      we will delete your personal data, unless further processing is still
      permitted by law. We will also delete your personal data if we are obliged
      to do so for other legal reasons. Applying these general principles, we
      will usually delete your personal data immediately
    </Paragraph>
    <Paragraph>
      <PTextList>
        <PTextListItem>
          after the legal permission has ceased to apply and provided that no
          other legal basis (e.g. commercial and tax law retention periods)
          intervenes. If the latter applies, we will delete the data after the
          other legal basis has ceased to apply;
        </PTextListItem>
        <PTextListItem>
          if your personal data is no longer required for the purposes we pursue
          and no other legal basis (e.g. commercial and tax law retention
          periods) intervenes. If the latter is the case, we will delete the
          data after the other legal basis has ceased to apply.
        </PTextListItem>
      </PTextList>
    </Paragraph>
    <PHeading size="medium" tag="h2">
      10. Rights of data subjects
    </PHeading>
    <Paragraph>
      <strong>Right to access:</strong> You have the right to receive
      information about your personal data stored by us.
    </Paragraph>
    <Paragraph>
      <strong>Right to rectification and erasure:</strong> You can demand that
      we correct incorrect data and, if the legal requirements are met, delete
      your data.
    </Paragraph>
    <Paragraph>
      <strong>Restriction of processing:</strong> You can demand that we
      restrict the processing of your data, provided that the legal requirements
      are met.
    </Paragraph>
    <Paragraph>
      <strong>Data portability:</strong> If you have provided us with data on
      the basis of a contract or consent, you may, if the legal requirements are
      met, demand that the data you have provided us with are handed over in a
      structured, common and machine-readable format or that we transfer it to
      another controller.
    </Paragraph>
    <Paragraph>
      <strong>Objection:</strong> You have the right to object at any time to
      data processing by us based on the safe-guarding of legitimate interests
      for reasons arising from your particular situation. If you make use of
      your right to object, we will stop processing the data unless we can prove
      compelling reasons for further processing worthy of protection which
      outweigh your rights and interests.
    </Paragraph>
    <Paragraph>
      <strong>Objection to direct marketing:</strong> If we process your
      personal data for the purpose of direct marketing, you have the right to
      object to our processing of your data for this purpose at any time. If you
      exercise your right to object, we will stop processing your data for this
      purpose.
    </Paragraph>
    <Paragraph>
      <strong>Revocation of consent:</strong> If you have given us your consent
      to process your personal data, you can revoke it at any time with effect
      for the future. The legality of the processing of your data until
      revocation remains unaffected.
    </Paragraph>
    <Paragraph>
      <strong>Right to lodge a complaint with a supervisory authority:</strong>{' '}
      You can also lodge a complaint with the competent supervisory authority if
      you believe that the processing of your data violates applicable law. You
      can contact the supervisory authority responsible for your place of
      residence or your country or the supervisory authority responsible for us.
    </Paragraph>
    <Paragraph>
      <strong>Your contact with us and the exercise of your rights:</strong>{' '}
      Furthermore, you can contact us free of charge if you have questions
      regarding the processing of your personal data and your rights as a data
      subject. Please contact us at https://www.porsche.com/privacy-contact/ or
      by letter mail to the address provided under Section 1. Please make sure
      that we can definitely identify you. If you revoke your consent, you can
      alternatively choose the contact method that you used when you gave your
      consent.
    </Paragraph>
    <PHeading size="medium" tag="h2">
      11. Effective date
    </PHeading>
    <Paragraph>
      The latest version of this Privacy Policy applies. This version dates from
      18.01.2021.
    </Paragraph>
  </>
);
